input, button, select, textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

html, body {
    font-family: @primary-font;
    font-size: @base-font-size;
    line-height: @base-line-height;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    color: @primary-color;
    background-color: @secondary-color;
}

body {
    scrollbar-face-color: @secondary-color;
    scrollbar-track-color: @primary-color;
}

input:focus,
button:focus {
    outline: none;
}

img {
    max-width: 100%;
    height: auto;
}

button {
    cursor: pointer;
    border: none;
    background-color: transparent;
}

strong {
    font-weight: 700;
}

input,
textarea {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid fade(@primary-color, 20%);
    font-size: 15px;
    width: 100%;
    height: 45px;
}

textarea {
    height: 75px;
}
.button,
input[type=submit] {
    border: 1px solid @primary-color;
    border-radius: 0;
    text-transform: uppercase;
    letter-spacing: 3px;
    height: 40px;
    width: auto;
    padding: 0 50px;
    float: left;
    margin-top: 40px;
    transition: all 0.25s cubic-bezier(0.77, 0, 0.175, 1);
    &:hover {
        background-color: @primary-color;
        color: @secondary-color;
    }
    @media (min-width: @screen-xlg-min) {
        margin-left: 15px;
    }
}

p {
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.5px;
    font-weight: @weight-normal;
    i {
        font-style: italic;
    }
    b, strong {
        font-weight: @weight-bold;
    }
}

h3 {
    font-size: 40px;
    font-weight: @weight-thin;
    margin-bottom: 30px;
    text-align: center;
}

a,
a:hover,
a:focus {
    color: @primary-color;
    text-decoration: underline;
}