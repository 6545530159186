.page-blog {
    .section,
    .container-fluid,
    .container,
    .row,
    div[class*="col-"],
    .content-section {
        height: auto;
    }
}

.blog-post-header {
    position: relative;
    overflow: hidden;

    .blog-sidebar & {
        padding-top: 12px;
    }
}
.blog-post-author {
    width: 40px;
    height: 40px;
    @media (min-width: @screen-sm-min) {
        width: 80px;
        height: 80px;
    }
    background-size: cover;
    background-position: center center; 
    border-radius: 50%;
    float: left;

    .image-cover & {
        float: none;
        margin: 0 auto;
    }
}
.blog-post-date {
    font-size: 13px;
    letter-spacing: 2px;
    @media (min-width: @screen-sm-min) {
        letter-spacing: 3.8px;
    }
    @media (max-width: @screen-sm-max) {
        width: ~"calc(100% - 80px)";
        line-height: 22px;
    }
    @media (min-width: @screen-sm-min) {
        margin-top: 24px;
    }

    .image-cover & {
        float: none;
        position: initial;
        text-align: center;
        color: white;
        font-size: 15px;
        margin-top: 17px;
        width: 100%;
    }

    &.block {
        float: none;
        text-align: left;
        margin-bottom: 42px;
    }
}
.blog-post {
    &:hover {
        .overcard {
            background-color: @primary-color;
            p, h1 {
                color: @secondary-color;
            }
            a {
                color: #D7B64C;
            }
        }
    }
    &.original {
        .banner {
            height: 320px;
            @media (min-width: @screen-xlg-min) {
                height: 500px;
            }
        }
    }
}

.blog-post-title {
    font-size: 25px;
    letter-spacing: 4px;
    text-transform: uppercase;
    line-height: 22px;
    @media (min-width: @screen-sm-min) {
        font-size: 34px;
        letter-spacing: 7px;
        line-height: 30px;
    }
    &:after {
        content: "";
        display: block;
        width: 50px;
        height: 2px;
        margin: 31px 0 0 2px;
        background-color: @primary-color;
    }
}
.blog-post-intro {
    // font-style: italic;
    font-size: 14px;
    line-height: 23px;
    letter-spacing: 2px;
    font-family: @secondary-font;
    @media (min-width: @screen-xlg-min) {
        line-height: 34px;
        letter-spacing: 4px;
    }
}
.readfull {
    font-size: 13px;
    letter-spacing: 4px;
    text-decoration: none;
    color: @primary-color;
    &:after {
        content: "";
        display: inline-block;
        background-image: url('../images/arrow.png');
        width: 15px;
        height: 9px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        margin-left: 8px;
    }
    &:hover {
        color: @primary-color;
    }

    &.bottom {
        position: absolute;
        bottom: 42px;
    }
}

.image-blog3 {
    height: 250px;
    @media (min-width: @screen-sm-min) {
        height: 450px;
    }
}

.blog-post-content {
    padding: 0 20px;
    @media (min-width: @screen-sm-min) {
        padding: 0 65px;
    }

    &.overcard {
        padding: 40px 40px 20px;
        position: relative;
        margin: 0 10px;
        top: -20px;
        background-color: @secondary-color;

        .blog-post-author {
            position: absolute;
            top: -44px;
            left: 50%;
            margin-left: -40px;
        }
        .blog-post-date {
            float: none;
            text-align: center;
            top: 0;
        }
        .blog-post-title {
            font-size: 22px;
            text-align: center;
            line-height: 31px;
            &:after {
                display: none;
            }
        }
    }

    &.small {
        padding: 0 25px;
        height: 440px;
        @media (min-width: @screen-sm-min) {
            height: 300px;
        }
        @media (min-width: @screen-md-min) {
            height: 450px;
        }
        @media (min-width: @screen-xlg-min){
            padding: 0 55px;
        }
        .blog-post-title {
            font-size: 22px;
            letter-spacing: 4.5px;
            font-weight: 300;
            line-height: 26px;
            &:after {
                display: none;
            }
        }
        .blog-post-author {
            width: 40px;
            height: 40px;
            @media (min-width: @screen-sm-min) {
                margin-top: 20px;
            }
            span {
                color: #D4B137;
            }
        }
        .blog-post-intro {
            font-size: 13px;
            line-height: 25px;
            @media (min-width: @screen-xlg-min) {
                line-height: 30px;
            }
        }
        .blog-post-date {
            width: ~"calc(100% - 40px)";
            text-align: left;
            padding-left: 12px;
            span {
                color: #D4B137;
            }
        }
    }
}


// pagination
.paginationblog {
    text-align: center;
    font-size: 16px;
    // font-style: italic;
    padding: 65px 0;
    letter-spacing: 3.5px;
    &.includeimage {
        padding: 0;
        position:  relative;
        overflow: hidden;
        @media (max-width: @screen-sm-max) {
            margin-bottom: 2px;
        }
        &:before,
        &:after {
            display: none;
        }
        .banner {
            display: block;
            width: 25%;
            max-width: 400px;
            @media (min-width: @screen-sm-min) {
                width: 45%;
            }
        }
        a {
            color: @primary-color;
        }
        .info {
            position: relative;
            width: 75%;
            @media (min-width: @screen-sm-min) {
                width: 55%;
            }
            &:before {
                content: "";
                width: 24px;
                height: 14px;
                display: inline-block;
                top: 73px;
                position: absolute;
            }
            span {
                display: block;
                &.date-pagination {
                    @media (max-width: @screen-md-max) {
                        display: none;
                    }
                }
                &.title-pagination {
                    font-size: 18px;
                    font-style: normal;
                    // font-family: @new-font;
                    text-transform: uppercase;
                }
            }
        }
        &.oldpost {
            border-right: 1px solid @secondary-color;
            .banner,
            .info {
                float: left;
            }
            .info {
                text-align: left;
                padding: 14px 20px 14px 65px;
                @media (min-width: @screen-sm-min) {
                    padding: 21px 21px 0px 65px;
                }
                @media (min-width: @screen-md-min) {
                    padding: 32px 32px 32px 77px;
                }
                &:before {
                    background-image: url('../images/arrow.png');
                    transform: rotate(180deg);
                    left: 20px;
                }                
            }
        }
        &.newpost {
            border-left: 1px solid @secondary-color;
            background-color: #F9F7F7;
            .banner,
            .info {
                float: right;
            }
            .info {
                text-align: right;
                padding: 14px 65px 14px 20px;
                @media (min-width: @screen-sm-min) {
                    padding: 21px 65px 0px 21px;
                }
                @media (min-width: @screen-md-min) {
                    padding: 32px 77px 32px 32px;
                }
                &:before {
                    background-image: url('../images/arrow.png');
                    right: 20px;
                }
            }
        }
    }
}
.oldpost {
    background-color: #F9F7F7;
    color: @primary-color;
    &:before {
        content: "";
        background-image: url('../images/arrow.png');
        width: 24px;
        height: 14px;
        display: inline-block;
        margin-right: 15px;
        position: relative;
        top: 2px;
        .rotate(180deg);
    }
}
.newpost {
    background-color: @primary-color;
    color: @secondary-color;
    &:after {
        content: "";
        background-image: url('../images/right-arrow.png');
        width: 24px;
        height: 14px;
        display: inline-block;
        margin-left: 15px;
        position: relative;
        top: 2px;
    }
}

.blog-sidebar {
    div[class*="container-"] {
        padding: 0 20px;
        @media (min-width: @screen-sm-min) {
            padding: 0 55px;
        }
    }
}
.sidebar-content {
    padding: 50px 15px 0 15px;
    @media (min-width: @screen-md-min) {
        padding: 0 0px 0 40px;
    }
    @media (min-width: @screen-xlg-min) {
        padding: 0 44px 0 62px;
    }
}
.feed-instagram {
    text-align: center;
    @media (min-width: @screen-sm-min) {
        text-align: left;
    }
    li {
        display: inline-block;
        @media (min-width: @screen-xlg-min) {
            margin: 0 20px 20px 0;
            &:nth-child(3n+0) {
                    margin-right: 0;
            }
        }
    }
}

.sidebar-recent-posts {
    li {
        position: relative;
        margin-bottom: 2px;
    }
    .blog-post-author {
        position: absolute;
        top: 14px;
        z-index: 1;
        left: 9px;
        @media (min-width: @screen-sm-min) {
            left: 0;
            top: 10px;
        }
    }
    .post-cart {
        padding: 12px 10px 10px 60px;
        min-height: 100px;
        @media (min-width: @screen-sm-min) {
            margin-left: 40px;
        }
        a {
            text-decoration: none;
            &:hover {
                text-decoration: none;
            }
        }
    }
    .blog-post-date {
        float: none;
        top: 0;
        text-align: left;
        letter-spacing: 2.5px;
        line-height: 28px;
        color: #D4B137;
            &:hover {
                text-decoration: none;
            }
    }
    .blog-post-title {
        font-size: 16px;
        font-weight: 100;
        letter-spacing: 3px;
        font-style: italic;
        color: @primary-color;
            &:hover {
                text-decoration: none;
            }
        &:after {
            display: none;
        }
    }
}

.sidebar-title {
    font-size: 15px;
    font-style: italic;
    letter-spacing: 2px;
    margin-bottom: 5px;
    &:before {
        content: "";
        display: inline-block;
        width: 20px;
        height: 1px;
        background-color: @primary-color;
        margin-right: 17px;
        position: relative;
        top: -2px;
    }
}
.sidebar-categories {
    li {
        a {
            font-size: 16px;
            font-style: italic;
            text-decoration: none;
            color: @primary-color;
            letter-spacing: 3px;
            margin: 0 0 17px 0px;
            display: block;
            &:before {
                content: "";
                display: inline-block;
                width: 12px;
                height: 1px;
                background-color: #D4B137;
                margin-right: 17px;
                position: relative;
                top: -2px;
            }
            span {
                color: fade(@primary-color, 50%);
            }
        }
    }
}
.sidebar-tags {
    li {
        display: inline-block;
    }
    a {
        font-size: 13px;
        font-style: italic;
        color: @primary-color;
        text-decoration: none;
        border: 2px solid @primary-color;
        padding: 0px 11px;
        display: block;
        letter-spacing: 2.5px;
        margin: 0 6px 11px 0;
    }
} 

.search-form {
    padding: 0;
    position: relative;

    input[type=text] {
        padding-right: 35px;
    }
    
    input[type=submit] {
        border: none;
        position: absolute;
        bottom: 2px;
        right: 2px;
        height: 36px;
        background: 0 0;
        padding: 0 15px 5px;
        width: 20px;
        margin: 0;
        color: transparent;
        background-image: url('../images/search.png');
        background-repeat: no-repeat;
        background-position: center center;
    }
}

.share-post {
    li {
        display: inline-block;
        margin: 0 5px;

        a {
            border: 2px solid @secondary-color;
            border-radius: 50%;
            display: block;
            width: 40px;
            height: 40px;
            text-align: center;
            color: @secondary-color;
            padding-top: 6px;
            background-repeat: no-repeat;
            background-position: center center;
        }
    }
    .image-cover & {
    }
}

.blog-2-image {
    height: 350px;
    background-size: cover;
    background-position: center center;
    @media (min-width: @screen-sm-min) {
        height: 590px;
    }
}
.blog-2-text {
    height: 700px;
    .blog-post-header {
        margin-top: 50px;
    }
    @media (min-width: @screen-sm-min) {
        height: 590px;
        .blog-post-header {
            margin-top: 0;
        }   
    }
}

.intro {
    height: 100%;
    background-color: @primary-color;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;
    > div {
        width: 100%;
        @media (min-width: @screen-sm-min) {
            // margin-top: 8%;
        }
    }
    .image-cover {
        height: 100%;
        background-size: cover;
        background-position: center center;
        position: relative;
        > div {
            width: 100%;
        }
    }
}

.primary-title {
    font-weight: @weight-normal;
    line-height: 56px;
    font-size: 30px;
    color: @primary-color;
    text-transform: uppercase;
    @media (min-width: @screen-sm-min) {
        font-size: 36px;
        letter-spacing: -0.3px;
    }
    strong {
        display: block;
        font-size: 50px;
        font-weight: 400;
        @media (min-width: @screen-sm-min) {
            font-size: 105px;
            letter-spacing: 5px;
            line-height: 87px;
        }
    }
    &.invert {
        color: @secondary-color;
    }
}
.post-primary-title {
    font-size: 23px;
    font-style: italic;
    letter-spacing: 4px;
    line-height: 31px;
    @media (min-width: @screen-sm-min) {
        font-size: 32px;
        letter-spacing: 5.5px;
        line-height: 40px;
    }
    &.invert {
        color: @secondary-color;
    }
}