/* Bootstrap No-Gutter Grid */
.row-no-gutter {
  margin-right: 0;
  margin-left: 0;
  [class^="col-"],
  [class*=" col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}
.container-full {
  padding-left: 0px;
  padding-right: 0px;
  width: 100%;
}
// 

.section {
    overflow: auto;
    position: relative;
    height: 100%;
}
.container-fluid,
.container {
    height: 100%;
    @media (min-width: @screen-sm-min) {
      padding-left: ~"calc(15px + @{navbar-vertical-width})";
    }
    @media (min-width: @screen-xlg-min) {
        padding-left: ~"calc(15px + @{navbar-vertical-width-xl})";
    }
    &.container-full {
      width: 100%;
      @media (min-width: @screen-sm-min) {
        padding-left: @navbar-vertical-width;
      }
      @media (min-width: @screen-xlg-min) {
        padding-left: @navbar-vertical-width-xl;
      }
      .classic-menu & {
        padding-left: 0;
      }
    }
}
.row,
div[class*="col-"],
.content-section {
    height: 100%;
}
.content-section {
    position: relative;
}





.container {
    .form-container {
        padding: 0 50px;
    }
    @media (min-width: @screen-xlg-min) {
        width: 1500px;
        .form-container {
            padding: 0 80px;
        }
    }
    @media (min-width: 1600px) {
        width: 1700px;
        .form-container {
            padding: 100px 120px 0;
        }
    }
}

