.left-menu {
    .navbar {
        position: absolute;
        width: 100%;
        z-index: 2;
        .social-links {
            // display: none;
        }
        .navbar-toggle .icon-bar {
            background-color: @primary-color;
        }
        .navbar-brand {
            .ir( 100px; 36px; '@{img-demo-path}/logo/logo-black.png'; center center);
            position: absolute;
            top: 6px;
            left: 6px;
        }
        @media (min-width: @grid-float-breakpoint) {
            position: fixed;
            width: @navbar-vertical-width;
            height: 100%;
            background: @secondary-color;
            @media (min-width: @screen-xlg-min) {
                width: @navbar-vertical-width-xl;
            }
            .navbar-header {
                position: fixed;
                background-color: @secondary-color;
                transition: background-color 0.5s;
                border-right: 1px solid fade(darken(@secondary-color, 50%), 25%);
                top: 0;
                left: 0;
                height: 100%;
                width: @navbar-vertical-width;
                z-index: 2;
                @media (min-width: @screen-xlg-min) {
                    width: @navbar-vertical-width-xl;
                }
                cursor: pointer;
                .navbar-brand {
                    position: absolute;
                    top: 0;
                    left: 90px;
                    width: 385px;
                    display: none;
                }
            }
            .navbar-toggle {
                display: block;
                padding: 9px 15px;
                margin: 0 auto;
                .vcenter();
                .icon-bar {
                    transition: background-color 0.5s;
                    background-color: @primary-color;
                    margin-left: auto;
                    margin-right: auto;
                }
            } 
            #navbar {
                position: fixed;
                top: 0;
                left: 0;
                width: 480px;
                background: @primary-color;
                height: 100% !important;
                padding-left: 100px;
                overflow: hidden;
                transition: all 0.5s;
                transform: matrix(1, 0, 0, 1, -480, 0);
                z-index: 1;
                @media (min-width: @screen-xlg-min) {
                    width: 995px;
                    transform: matrix(1, 0, 0, 1, -995, 0);
                }
                .navbar-nav {
                    float: none !important;
                    .vcenter();
                    width: ~'calc( 100% - 100px )';
                    > li {
                        float: none;
                        // animation 1
                        // transition: all 0.35s;
                        // transform: matrix(1, 0, 0, 1, -220, 0);
                        // transition-timing-function: ease-out;
                        // opacity: 0;

                        // animation 2
                        a {
                            opacity: 0;
                            transform: translateY(-10px);
                            text-decoration: none;
                            @media (min-width: @screen-xlg-min) {
                                padding: 15px 117px 15px;
                            }
                        }
                    }
                    &,
                    a {
                        color: @secondary-color;
                        font-size: 40px;
                        font-weight: 100;
                        text-transform: uppercase;
                        line-height: 60px;
                        &:hover,
                        &:focus {
                            background-color: transparent;
                            color: fade(@secondary-color, 80%);
                        }
                    }
                    // &:before,
                    // &:after {
                    //     content: "";
                    //     position: absolute;
                    //     height: 1px;
                    //     width: 70%;
                    //     top: -50px;
                    //     left: 15px;
                    //     background-color: #4a4a4a;
                    //     @media (min-width: @screen-xlg-min) {
                    //         width: 72%;
                    //         top: -57px;
                    //         left: 120px;
                    //     }
                    // }
                    // &:after {
                    //     height: 3px;
                    //     width: 40px;
                    //     border-right: 5px solid @primary-color;
                    //     @media (min-width: @screen-xlg-min) {
                    //         height: 3px;
                    //         width: 45px;
                    //     }
                    // }
                }
                .close-nav {
                    position: absolute;
                    top: 20px;
                    right: 34px;
                    width: 20px;
                    height: 20px;
                    background-image: url('@{img-path}/cross-menu.png');
                    background-position: center;
                    background-repeat: no-repeat;
                    cursor: pointer;
                    transition: all 0.2s;
                    @media (min-width: @screen-xlg-min) {
                        top: 30px;
                        right: 25px;
                    }
                    &:hover {
                        transform: rotate(90deg);
                    }
                }
            }
            .social-links {
                margin: 0;
                position: absolute;
                right: 25px;
                transition: all 0.5s;
                opacity: 0;
                bottom: 10px;
                @media (min-width: @screen-xlg-min) {
                    bottom: 60px;
                }
                li a {
                    transition: color 0.2s;
                    color: #6f6f6f;
                    &:hover {
                        color: #ccc;
                    }
                }
            }
            &:after {
                content: "";
                position: fixed;
                width: 100%;
                height: 100%;
                top: 0;
                background-color: fade(@primary-color, 50%);
                transition: all 0.5s;
                transform: matrix(1, 0, 0, 1, -2800, 0);
            }

            &.active {
                .navbar-header {
                    background-color: @primary-color;
                }
                .navbar-toggle {
                    .icon-bar {
                        background-color: @secondary-color;
                    }
                }
                &:after {
                    transform: matrix(1, 0, 0, 1, 0, 0);
                }
                #navbar {
                    transform: matrix(1, 0, 0, 1, 0, 0);
                    .social-links {
                        transition-delay: 1s;
                        opacity: 1;
                    }
                    .navbar-nav {
                        > li {
                            // animation 1
                            // transform: matrix(1, 0, 0, 1, 0, 0);
                            // opacity: 1;

                            // animation 2
                            a {
                                transform: translateY(0);
                                opacity: 1;
                                text-decoration: none;
                            }
                            
                            // delay in transiton menu items
                            .retarde (@index) when (@index <= 15){
                                &:nth-child(@{index}) {
                                    @time: @index / 10 + 0.2; 
                                    // animation 1
                                    // transition-delay: unit(@time, s);

                                    // animation 2
                                    a {
                                        transition: opacity .3s ease unit(@time, s), color .3s ease 0s, transform .3s ease unit(@time, s), transform .3s ease unit(@time, s);
                                    }
                                }
                               .retarde(@index + 1);
                            }
                            .retarde(1);

                        }
                    }
                }
            }
        }
    }
}


.classic-menu {
    .navbar-toggle .icon-bar {
        background-color: @primary-color;
    }
    .logo {
        position: relative;
        left: 4px;
        top: 5px;
        width: 120px;
        @media (min-width: @screen-sm-min) {
            .vcenter();
            width: auto;
            margin-left: 30px;
        }
        a {
            float: none;
        }
    } 
    .navbar {
        position: absolute;
        width: 100%;
        z-index: 2;
        .navbar-nav {
            > li > a {
                color: lighten(@primary-color, 40%);
                text-decoration: none;
                &:focus,
                &:hover {
                    background-color: transparent;
                    color: @primary-color;
                }
            }
            > li.active > a{
                background-color: transparent;
                color: @primary-color;
            }
        }
        @media (min-width: @grid-float-breakpoint) {
            .navbar-nav {
                margin: 20px 35px;
                @media (min-width: @screen-xlg-min) {
                    margin: 33px 35px;
                }
                > li > a {
                    padding: 10px 22px;
                    text-transform: uppercase;
                    font-size: 13px;
                    letter-spacing: 1.2px;
                }
            }
        }
    }
}

.nav > li, 
.nav > li {
    @media (max-width: 767px) {
        > a {
            text-decoration: none;
            text-align: center;
            &:hover,
            &:focus {
                background-color: transparent;
            }
        }
        &.active {
            > a {
                background-color: #eeeeee;
            }
        }
    }
}