.vcenter() {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
}

.hcenter() {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
}

.center-content() {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
}

.rotate(@degrees) {
  -webkit-transform: rotate(@degrees);
      -ms-transform: rotate(@degrees); // IE9 only
       -o-transform: rotate(@degrees);
          transform: rotate(@degrees);
}

.text-center() {
    text-align: center;
}

.clearfix() {
    &:before,
    &:after {
        content: "";
        display: table;
    }
    &:after {
        clear: both;
    }
}

.full-bkg() {
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.bring-to-front() {
    position: relative;
    z-index: 1;
}

.bgcover() {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.imageBW() {
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);  /* For Webkit browsers */
    filter: gray;  /* For IE 6 - 9 */
    -webkit-transition: all .2s ease;  /* Transition for Webkit browsers */
    transition: all .2s ease;
    &:hover {
        filter: grayscale(0%);
        -webkit-filter: grayscale(0%);
        filter: none;
    }
}
.bgBW() {
    -moz-filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
    -o-filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
    -webkit-filter: grayscale(100%);
    filter: gray;
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
    transition: all .2s ease;
    &:hover {
        filter: grayscale(0%);
        -webkit-filter: grayscale(0%);
        filter: none;
    }
}

.hidden-xs-down {
    @media (max-width: 570px) {
        display: none;
    }
}

.ir( @sizeW:10px; @sizeH:10px; @url-image; @pos:center center) {
    display: block; 
    border: 0; 
    text-indent: -999em; 
    overflow: hidden; 
    text-align: left; 
    direction: ltr;

    width: @sizeW;
    height: @sizeH;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: @pos;
    background-image: url("@{url-image}");
    background-size: contain;
}
