.left-menu .slider-full {
  position: relative;
  @media (min-width: @grid-float-breakpoint) {
    width: ~"calc(100% - 55px)";
    margin-left: 55px;
  }
  @media (min-width: @screen-xlg-min) {
    width: ~"calc(100% - 85px)";
    margin-left: 85px;
  }
}

.classic-menu {
  .tp-bannertimer {
    top: 50px;
    @media (min-width: @screen-sm-min) {
      top: 80px;
    }
    @media (min-width: @screen-xlg-min) {
      top: 106px;
    }
  }
}

.rev_slider_wrapper {
   overflow: hidden !important; 
    width: 100% !important;
    left: 0 !important;
}
.hermes .tp-tab-image {
    height: 100%;
    &:hover {
      opacity: 0.4;
    }
}
.hermes .tp-tab {
    padding-right: 0;
    background-color: @primary-color;
}

// pagianation
.arrowpag {
  &.tparrows {
    background: transparent;
  }
  .arrow-text {
    display: block;
    color: #fff;
    position: absolute;
    top: 50%;
    margin-top: -12px;
    width: 50px;
    font-size: 12px;
    letter-spacing: 2px;
    font-weight: 600;
    left: ~"calc(100% + 10px)";
    @media (min-width: @screen-xlg-min) {
      left: ~"calc(100% + 26px)";
    }
  }
  &.tp-rightarrow {
    .arrow-text {
      left: auto;
      text-align: right;
      right: ~"calc(100% + 10px)";
      @media (min-width: @screen-xlg-min) {
          right: "calc(100% + 26px)";
      }
    }
  }
}

// thumbnails
#thumbsButton {
  background: transparent;
  color: @secondary-color;
  margin: 0;
  text-align: center;
  position: absolute;
  bottom: 0px;
  z-index: 1;
  left: 0;
  // margin-left: -20px;
  // width: 40px;
  // left: 50%;
  // cursor: pointer;
  
  .ir( 29px; 17px; '@{img-path}/arrow-thumbs.svg'; center center);
  display: none;
  background-size: 29px;
  height: 60px;
  width: 100%;

  transition: all 0.5s;
  &::selection {
    background: transparent; /* WebKit/Blink Browsers */
  }
  &::-moz-selection {
    background: transparent; /* Gecko Browsers */
  }
  @media (min-width: @screen-md-min) {
    display: block;
  }
}
.tp-tabs {
  transition: all 0.5s;
  transform: matrix(1, 0, 0, 1, 0, 0) !important;
}
.showThumbnails {
  .tp-tabs {
    transform: matrix(1, 0, 0, 1, 0, -160) !important;
  }
  #thumbsButton {
    transform: matrix(1, 0, 0, 1, 0, -160) !important;
  }
}

.tparrows.tp-leftarrow:before,
.tparrows.tp-rightarrow:before {
  content: '';
  background-position: center;
  display: block;
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-size: 32px;
  @media (min-width: @screen-xlg-min) {
    background-size: auto;
  }
}
.tparrows.tp-leftarrow:before {
  background-image: url('@{img-path}/left.png');
}
.tparrows.tp-rightarrow:before {
  background-image: url('@{img-path}/right.png');
}


.scrollButton {
    display: block;
    width: 26px;
    height: 46px;
    border-radius: 13px;
    border: 1px solid @secondary-color;
    position: absolute;
    bottom: 30px;
    left: 50%;
    margin-left: -13px;
    z-index: 99999;
    cursor: pointer;
    &:after {
      content: "";
      display: block;
      margin: 6px auto;
      width: 2px;
      height: 6px;
      border-radius: 4px;
      background: #fff;
      border: 1px solid transparent;
      -webkit-animation-duration: 1s;
      animation-duration: 1s;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
      -webkit-animation-name: scroll;
      animation-name: scroll;
    }
}
    @-webkit-keyframes scroll {
        0% {
            opacity: 1;
            -webkit-transform: translateY(0);
            transform: translateY(0);
        }

        100% {
            opacity: 0;
            -webkit-transform: translateY(20px);
            transform: translateY(20px);
        }
    }

    @keyframes scroll {
        0% {
            opacity: 1;
            -webkit-transform: translateY(0);
            -ms-transform: translateY(0);
            transform: translateY(0);
        }

        100% {
            opacity: 0;
            -webkit-transform: translateY(20px);
            -ms-transform: translateY(20px);
            transform: translateY(20px);
        }
    }