.page-gallery {
    padding: 0;
}

#grid-gallery {
    margin: @gutter-grid-gallery/2;
    margin-top: 50px;
    // @media (min-width: @screen-sm-min) {
        // margin-top: 0;
        // .home-gallery & {    
        //     margin-top: 91px;
        //     @media (min-width: @screen-xlg-min) {
        //         margin-top: 166px;
        //     }
        // }
    // }
    .home-gallery & {
        margin-top: 0;
    }
    .classic-menu & {
        margin-top: 60px;
        @media (min-width: @screen-xlg-min) {
            margin-top: 106px;
        }
    }
}

.grid-item {
    width: 50%;
    @media (min-width: @screen-sm-min) {
        width: 33%;
    }
    @media (min-width: @screen-xlg-min) {
        width: 20%;    
    }
    padding: 0 @gutter-grid-gallery/2 @gutter-grid-gallery;
    img {
        display: block;
        cursor: pointer;
        z-index: 1;
        position: relative;
        transition: opacity 0.25s cubic-bezier(0.77, 0, 0.175, 1);
        width: 100%;
        &:hover {
            opacity: 0.8;
        }
    }
    &:after {
        content: "";
        width: ~"calc(100% - @{gutter-grid-gallery})";
        height: ~"calc(100% - @{gutter-grid-gallery})";
        display: block;
        position: absolute;
        background-color: @primary-color;
        top: 0;
        left: @gutter-grid-gallery/2;
    }
}

#filters {
    text-align: center;
    font-family: @secondary-font;
    font-size: 16px;
    @media (max-width: 767px) {
        margin-top: 50px;
    }
    li {
        display: inline-block;
        cursor: pointer;
        color: #979797;
        margin: 23px 7px;
        @media (min-width: @screen-sm-min) {
            margin: 33px 10px;
        }
        @media (min-width: @screen-md-min) {
            margin: 33px 22.5px;
        }
        @media (min-width: @screen-xlg-min) {
            margin: 71px 22.5px; 
        }
        &:not(:first-child) {
            &:before {
                content: "/";
                position: relative;
                color: #979797;
                left: -7px;
                @media (min-width: @screen-sm-min) {
                    left: -11px;
                }
                @media (min-width: @screen-md-min) {
                    left: -25px;
                }
            }
        }
        &.is-checked {
            font-weight: 500;
            color: @primary-color;
        }
    }
}