.layer-page {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1;
    top: 100%;
    left: 0;
    transition: all 0.5s;
    background-color: @secondary-color;
    // .classic-menu & {
    //     height: 'calc(100% - 80px)';
    //     margin-top: 80px;
    // }
    .container-vcenter {
        .vcenter();
        width: ~"calc(100% - 140px)";
    }
}

.close-layer {
    .ir( 20px; 20px; '@{img-path}/cross.svg'; center center);
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 9;
    transition: all 0.2s;
    &:hover {
        transform: rotate(90deg);
    }
    .classic-menu & {
        display: none;
    }
    @media (max-width: @screen-sm-min) {
        display: none;
    }
}