.slider-full {
    .slide-pre-title,
    .slide-title,
    .slide-title2,
    .slide-subtitle {
        text-align: center !important;
    }
    .slide-subtitle,
    .slide-subtitle2 {
        white-space: normal !important;
    }
}
.slide-pre-title {
    font-weight: 200;
    letter-spacing: 6px;
}
.slide-title {
    letter-spacing: 12.1px;
    text-align: center;
}
.slide-title2 {
    font-weight: 500;
    text-align: center;
    letter-spacing: 5px !important;
    @media (min-width: @screen-sm-min) {
        letter-spacing: 9px !important;
    }
}
.slide-subtitle {
    font-family: @tertiary-font;
    text-align: center;
    min-width: 300px;
    max-width: 300px;
    font-size: 15px !important;
    letter-spacing: 0.5px !important;
    line-height: 24px !important;
    @media (min-width: @screen-sm-min) {
        min-width: 400px;
        max-width: 400px;
    }
    @media (min-width: @screen-md-min) {
        min-width: 600px;
        max-width: 600px;
    }
}
.slide-subtitle2 {
    font-family: @tertiary-font;
    text-align: center;
    // opacity: 0.7 !important;
    letter-spacing: 3px !important;
    @media (min-width: @screen-sm-min) {
        letter-spacing: 7.5px !important;
    }
}
.rb-btn a {
    text-decoration: none;
    padding: 10px 30px;
    border: 1px solid red;
    border-radius: 0;
    font-size: 15px;
    text-transform: uppercase;
}
// .slide-logo img {
//     width: 100% !important;
//     height: 100% !important;
// }
.slide-pre-title,
.slide-title,
.slide-title2,
.slide-subtitle,
.slide-subtitle2,
.rb-btn a {
    &.invert {
        color: @secondary-color;
        border-color: @secondary-color;
    }
}