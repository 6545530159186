/*------------------------------------------------------------------
[Table of contents]
0. Helpers + Plugins 
1. Reset + default
2. Grid system
3. Header / #header
4. Footer / #footer
5. Navigation / #navbar
6. Components
    6.01 Loader
    6.02 Main Slider
    6.03 Layer page
    6.04 Albums carousel
    6.05 Contact
    6.06 Social links
    6.07 Gallery grid
    6.08 Overlay menu full
    6.09 Post share
    6.10 Player video
    6.11 Slider text
    6.12 Newsletter form
    6.13 contdown
7. Pages
8. Variations
9. Demos
-------------------------------------------------------------------*/

/*------------------------------------------------------------------
[0. Helpers + Plugins]
*/
@import "helpers/all";
// Bootstrap
// @import "/bower_components/bootstrap/less/bootstrap.less";
// @icon-font-path: "../fonts/glyphicons/";

// Font Awesome
// @import '/bower_components/font-awesome/less/font-awesome.less';
// @fa-font-path: '../fonts/fontawesome/';

// Slider Revolution 

/*------------------------------------------------------------------
[1. Reset + default]
*/
@import "base/all";
@import "layout/all";

/*------------------------------------------------------------------
[6. Components]
*/
@import "components/all";

/*------------------------------------------------------------------
[7. Pages]
*/
@import "pages/all";

/*------------------------------------------------------------------
[8. Variations]
*/
@import "variations/all";

/*------------------------------------------------------------------
[9. Demos]
*/
@import "demos";


// body {
//     background-color: violet;

//     @media (min-width: @screen-sm-min) {
//         background-color: red;
//     }
//     @media (min-width: @screen-md-min) {
//         background-color: orange;
//     }
//     @media (min-width: @screen-lg-min) {
//         background-color: yellow;
//     }
//     @media (min-width: @screen-xlg-min) {
//         background-color: black;
//     }
// } 



body {
  margin: 0;
  padding: 0; 
}
.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}



// https://www.sitepoint.com/architecture-sass-project/
// sass/ 
// | 
// |– base/ 
// |   |– _reset.scss       # Reset/normalize 
// |   |– _typography.scss  # Typography rules 
// |   ...                  # Etc… 
// | 
// |– components/ 
// |   |– _buttons.scss     # Buttons 
// |   |– _carousel.scss    # Carousel 
// |   |– _cover.scss       # Cover 
// |   |– _dropdown.scss    # Dropdown 
// |   |– _navigation.scss  # Navigation 
// |   ...                  # Etc… 
// | 
// |– helpers/ 
// |   |– _variables.scss   # Sass Variables 
// |   |– _functions.scss   # Sass Functions 
// |   |– _mixins.scss      # Sass Mixins 
// |   |– _helpers.scss     # Class & placeholders helpers 
// |   ...                  # Etc… 
// | 
// |– layout/ 
// |   |– _grid.scss        # Grid system 
// |   |– _header.scss      # Header 
// |   |– _footer.scss      # Footer 
// |   |– _sidebar.scss     # Sidebar 
// |   |– _forms.scss       # Forms 
// |   ...                  # Etc… 
// | 
// |– pages/ 
// |   |– _home.scss        # Home specific styles 
// |   |– _contact.scss     # Contact specific styles 
// |   ...                  # Etc… 
// | 
// |– themes/ 
// |   |– _theme.scss       # Default theme 
// |   |– _admin.scss       # Admin theme 
// |   ...                  # Etc… 
// | 
// |– vendors/ 
// |   |– _bootstrap.scss   # Bootstrap 
// |   |– _jquery-ui.scss   # jQuery UI 
// |   ...                  # Etc… 
// | 
// | 
// `– main.scss             # primary Sass file 