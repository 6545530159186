header {
    position: absolute;
    z-index: 1;
    top: 25px;
    left: 30px;
    display: none;
    @media (min-width: @screen-sm-min) {
        display: block;
    }
    .left-menu & {
        left: 100px;
        @media (min-width: @screen-xlg-min) {
            top: 50px;
            left: 150px;
        }
    }
    .home-gallery & {
        @media (min-width: @screen-sm-min) {
          left: ~"calc(15px + @{navbar-vertical-width})";
        }
        @media (min-width: @screen-xlg-min) {
            left: ~"calc(15px + @{navbar-vertical-width-xl})";
        }
    }
    .full-menu & {
        @media (max-width: @screen-sm-min) {
            display: block;
            top: 5px;
            left: 5px;
        }
    }
}
.logo {
    a {
        .ir( 120px; 41px; '@{img-demo-path}/logo/logo-white.png'; center center);
        cursor: default;
        @media (min-width: @screen-xlg-min) {
            width: 184px;
            height: 66px;
        }
        .home-gallery &,
        .classic-menu & {
            background-image: url('@{img-demo-path}/logo/logo-black.png');
        }
    }
} 