.page-contact {
    .content-section {
        @media (max-width: @screen-sm-min) {
            margin-top: 100px;
        }
        > div {
            @media (min-width: @screen-sm-min) {
                .vcenter();
            }
        }
    }
    div[class*="col-"] {
        height: auto;
        input[type=submit] {
            @media (max-width: @screen-sm-min) {
                float: none;
            }
        }
        @media (min-width: @screen-sm-min) {
            margin-top: 0;
            height: 100%;
        }
    }
}