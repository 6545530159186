.page-contact {
    h3 {
        font-size: 36px;
    }
}
#map {
    width: 100%;
    height: 400px;
    @media (max-width: @screen-sm-min) {
        margin-bottom: 15px;
    }
    @media (min-width: @screen-lg-min) {
        // height: 630px;
        height: 535px;
    }
    @media (min-width: @screen-xlg-min) {
        height: 800px;
    }
}
.address {
    text-transform: uppercase;
    text-align: center;
    margin-top: 30px;
    @media (min-width: @screen-xlg-min) {
        margin-top: 55px;
    }
    li {
        font-size: 16px;
        margin-bottom: 6px;
        @media (min-width: @screen-xlg-min) {
            margin-bottom: 15px;
        }
    }
}
.page-contact {
    text-align: center;
}
form {
    margin-top: 30px;
    @media (min-width: @screen-xlg-min) {
        margin: 60px 70px;
    }
    .form-group {
        padding: 0 10px;
        margin-bottom: 4px;
        label { display: none; }
        &.inline {
            float: left;
            width: 50%;
        }
    }
}
::-webkit-input-placeholder {
    font-style: normal;
    font-size: 15px;
}

:-moz-placeholder { /* Firefox 18- */
    font-style: normal;
    font-size: 15px;  
}

::-moz-placeholder {  /* Firefox 19+ */
    font-style: normal;
    font-size: 15px;  
}

:-ms-input-placeholder {  
    font-style: normal;
    font-size: 15px;  
}