.content-video {
    height: 100%;
}
#playerVideo {
    height: 100%;
}
#play-video {
    display: none;
    position: absolute;
    width: 100px;
    height: 100px;
    margin:-50px 0px 0px -50px;
    color:#fff;
    font-size: 75px;
    text-align: center;
    line-height: 65px;
    text-indent:-9999999px;
    background:url("@{img-path}/play.png") center center no-repeat rgba(0,0,0,0.5);
    z-index: 9999999;
    right: 0px;
    bottom: 0;
}
#play-video.playing {background:url("@{img-path}/pause.png") center center no-repeat rgba(0,0,0,0.5);}
#playerVideo:hover #play-video {display: block;}