.page-about {
    .image {
        position: relative;
        margin: 50px 0;
        margin: 23px 0 50px;
        text-align: center;
        width: 80%;
        margin: 50px auto 0; 
        @media (min-width: 2200px) {
            width: 100%;
            margin: 80px 0;
        }
        &:after {
            content: "";
            display: block;
            position: absolute;
            width: 84%;
            height: 111%;
            top: -6%;
            left: 8%;
            border: 2px solid @primary-color;
            @media (min-width: @screen-xlg-min) {
                height: 113%;
                top: -7%;
            }
            @media (min-width: 2200px) {
                width: 76%;
                height: 136%;
                top: -18%;
                left: 12%;
            }
        }
    }
    .author {
        position: absolute;
        margin: 0;
        right: 8%;
        top: 105%;
        font-size: 14px;
        &:before {
            content: "";
            display: inline-block;
            width: 51px;
            height: 1px;
            position: relative;
            top: -4px;
            right: 10px;
            background-color: @primary-color;
        }
        @media (min-width: @screen-xlg-min) {
            top: 108%;
        }
        @media (min-width: 2200px) {
            right: 12%;
            top: 122%;
        }
    }
    h3 {
        margin-top: 50px;

        @media (min-width: @screen-xlg-min) {
            margin-top: 120px;
            margin-bottom: 80px;
            position: relative;
            &:before {
                content: "";
                width: 7px;
                height: 35px;
                position: absolute;
                left: 50%;
                margin-left: -4px;
                top: -75px;
                background-image: url('@{img-path}/arrow-about.png')
            }
        }
        @media (min-width: 2200px) {
            margin-top: 190px;
        }
    }
    p {
        color: @color-paragraph;
        font-size: 13px;
        line-height: 19px;
        @media (min-width: @screen-sm-min) {
            margin: 0 100px 12px;
        }

        @media (min-width: @screen-xlg-min) {
            font-size: 18px;
            line-height: 23px;
            margin: 0 200px 25px;
        }
    }
    .social-links {
        margin-bottom: 0;
        margin-top: 16px;
        @media (min-width: @screen-xlg-min) {
            margin-top: 80px;
        }
    }
}