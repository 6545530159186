
/* Provides vertical center */
.vcenter {
    width: 100%;
    .vcenter();
    @media (min-width: @screen-sm-min) {}
}



//Generate voffset from 250px; to -40px
.verticaloffset (@index) when (@index <= 900){
   .voffset@{index}{
      // padding-bottom: unit(@index, px) !important;
   }
   .verticaloffset(@index + 10);
}
.verticaloffset(-40);



//Generate font size from 12px to 90px
.fontsize (@index) when (@index <= 90){
   .fz@{index}{
      // font-size: unit(@index, px) !important;
   }
   .fontsize(@index + 1);
}
.fontsize(12);



/* Provides center alignment */
.text-center {
    .text-center();
}

/* Fix scrolling */
.is-scroll-disabled {
    overflow: hidden;
}

// Clear floats
.clearfix {
    // .clearfix();
}

.invertcolor {
    background-color: @primary-color;
    &,
    a {
        color: @background-color;
    }
}

.color-bg-alternative {
    background-color: #FFF199;
}

.col2 {
    -webkit-column-count: 2; /* Chrome, Safari, Opera */
    -moz-column-count: 2; /* Firefox */
    column-count: 2;
    text-align: left;
}


.fa.invert {
    color: @secondary-color;
}