
.dropdown-icon {
    position: fixed;
    position: absolute;
    overflow: hidden;
    float: left;
    margin: 0;
    padding: 0;
    text-indent: -9999px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-shadow: none;
    border-radius: none;
    border: none;
    cursor: pointer;
    transition: background 0.2s;
    width: 50px;
    height: 50px;
    font-size: 16px; 
    z-index: 8;
    right: 10px;
    top: -2px;
    padding: 15px 11px;
    .full-menu & {
      @media (max-width: @screen-sm-min) {
        top: 2px;
      }
    }
    @media (min-width: @screen-md-min) {
      padding: 10px;
      top: 15px;
    }

    &#trigger-overlay {
      position: fixed;
    }

    @media (min-width: @screen-xlg-min) {
        top: 12px;
        right: 18px;
    }
}
  @media screen and (min-width: 40em) {
    .dropdown-icon {
      font-size: 20px; } }
  @media screen and (min-width: 64em) {
    .dropdown-icon {
      font-size: 20px; } }

.dropdown-icon:focus {
  outline: none; }

.dropdown-icon span {
  display: block;
  position: relative;
  background: @primary-color;
  top: 0;
  left: 0;
  right: 0;
  height: 2px;
  @media (min-width: @screen-md-min) {
    height: 4px; 
  }
}

.dropdown-icon span::before,
.dropdown-icon span::after {
  position: relative;
  display: block;
  left: 0;
  width: 100%;
  background-color: @primary-color;
  content: "";
  height: 2px;
  @media (min-width: @screen-md-min) {
    height: 4px; 
  }
}
.dropdown-icon.invert span {
  &,
  &:before,
  &:after {
    background-color: @secondary-color;
  }
}
.dropdown-icon span::before {
  top: -10px;
  @media (min-width: @screen-md-min) {
    top: -10px; 
  }
}

.dropdown-icon span::after {
  bottom: -8px;
  @media (min-width: @screen-md-min) {
    bottom: -6px; 
  }
}

.dropdown-icon--animate {
  background-color: transparent; }

.dropdown-icon--animate span {
    &,
    &:before,
    &:after {
        transition: background 0s 0.2s; 
    }
}

.dropdown-icon--animate span::before,
.dropdown-icon--animate span::after {
  transition-duration: 0.2s, 0.2s;
  transition-delay: 0.2s, 0s; }

.dropdown-icon--animate span::before {
  transition-property: top, -webkit-transform;
  transition-property: top, transform; }

.dropdown-icon--animate span::after {
  transition-property: bottom, -webkit-transform;
  transition-property: bottom, transform; }

.dropdown-icon--animate.is-active {
  background-color: transparent; }

.dropdown-icon--animate.is-active span {
  background: none;
  &:after,
  &:before {
        background-color: @secondary-color;
    } 
}

.dropdown-icon--animate.is-active span::before {
  top: 3px;
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg); }

.dropdown-icon--animate.is-active span::after {
  bottom: -1px;
  @media (min-width: @screen-md-min) {
    bottom: 1px;
  }
  -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
          transform: rotate(-45deg); }

.dropdown-icon--animate.is-active span::before,
.dropdown-icon--animate.is-active span::after {
  transition-delay: 0s, 0.2s; }







/* Menu style */
#overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: fade(@primary-color, 97%);
}
#overlay nav {
  text-align: center;
  position: relative;
  top: 50%;
  height: 60%;
  font-size: 54px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
      transform: translateY(-50%); }

#overlay ul {
  list-style: none;
  padding: 0;
  margin: 0 auto;
  display: inline-block;
  height: 100%;
  position: relative; }

#overlay ul li {
  display: block;
  height: 20%;
  height: calc(100% / 5);
  min-height: 54px; }

#overlay ul li a {
  display: inline-block;
  text-decoration: none;
  color: @secondary-color;
  transition: color 0.2s;
  font-size: 1.25rem;
  line-height: 1.875rem;
  letter-spacing: 0.125rem; }
  @media screen and (min-width: 40em) {
    #overlay ul li a {
      font-size: 3.125rem;
      line-height: 3.75rem;
      letter-spacing: 0.25rem; } }
  @media screen and (min-width: 64em) {
    #overlay ul li a {
      font-size: 3.75rem;
      line-height: 4.375rem;
      letter-spacing: 0.375rem; } }

#overlay ul li a:hover,
#overlay ul li a:focus {
  color: fade(@secondary-color, 70%);
  border-bottom: none; 
  text-decoration: none;
}


    /* Effects */
.overlay-hugeinc {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s, visibility 0s 0.5s; }

.overlay-hugeinc.open {
  opacity: 0.9;
  visibility: visible;
  transition: opacity 0.5s;
  z-index: 3; }

.overlay-hugeinc nav {
  -webkit-perspective: 1200px;
  perspective: 1200px; }

.overlay-hugeinc nav ul {
  opacity: 0.4;
  -webkit-transform: translateY(-25%) rotateX(35deg);
  transform: translateY(-25%) rotateX(35deg);
  transition: -webkit-transform 0.5s, opacity 0.5s;
  transition: transform 0.5s, opacity 0.5s; }

.overlay-hugeinc.open nav ul {
  opacity: 1;
  -webkit-transform: rotateX(0deg);
  transform: rotateX(0deg); }

.overlay-hugeinc.close nav ul {
  -webkit-transform: translateY(25%) rotateX(-35deg);
  transform: translateY(25%) rotateX(-35deg); }