.content-post {
    &.post-with-sidebar-left {
        margin-bottom: 80px;
        padding: 20px 10px;
        @media (min-width: @screen-sm-min) {
            margin-bottom: 130px;
            padding: 65px 190px 0 20px;
        }
        @media (min-width: @screen-md-min){
            padding: 65px 190px 0 60px;
        }
        @media (min-width: @screen-xlg-min) {
            padding: 65px 295px 0 90px;
        }
    }
    &.post-without-sidebar {
        background-color: @secondary-color;
        position: relative;
        top: -100px;
        padding: 0;
        @media (min-width: @screen-sm-min) {
            padding: 0px 25px;
        }
        @media (min-width: @screen-md-min) {
            padding: 0px 195px 0 25px;
        }
        @media (min-width: @screen-xlg-min) {
            padding: 0px 295px 0 90px;
        }
        
        .post-extra {
            overflow: hidden;
            @media (min-width: @screen-sm-min) {
                position: relative;
                left: -25px;
            }
            @media (min-width: @screen-md-min) {
                position: absolute;
                width: 100px;
                left: -100px;
            }
            @media (min-width: @screen-xlg-min) {
                left: -48px;
            }
            .blog-post-author {
                border-radius: 0;
                height: 100px;
                float: none;
                width: 32%;
                @media (min-width: @screen-sm-min) {
                    width: 100px;
                }
                @media (max-width: @screen-md-max) {
                    float: left;
                }
            }
            .button-blog {
                width: 32%;
                height: 100px;
                background-color: #F9F7F7;
                display: block;
                margin-bottom: 1px;
                background-position: center center;
                background-repeat: no-repeat;
                @media (min-width: @screen-sm-min) {
                    width: 100px;
                }
                @media (max-width: @screen-md-max) {
                    float: left;
                }
            }
        }
        .blog-post-date {
            padding-top: 24px;
            margin-top: 0;
        }
    }
    .primary-title {
        margin-bottom: 28px;
    }
    h1, h2, h3, h4, h5, h6 {
        font-weight: 400;
        text-transform: uppercase;
    }
    h1 {
        font-size: 46px;
        margin: 71px 0px 42px;
        letter-spacing: 2.5px;        
    }
    h2 {
        font-size: 26px;
        margin: 71px 0px 42px;
        letter-spacing: 2.5px;
    }
    h3 {
        font-size: 20px;
        margin: 50px 0px 20px;
        letter-spacing: 1.5px;
    }
    h4 {
        font-size: 18px;
        margin: 30px 0px 10px;
    }
    h5 {
        font-size: 16px;
        margin: 20px 0px 10px;   
    }
    h6 {
        font-size: 14px;
        margin: 15px 0px 5px;   
    }
    p {
        margin-bottom: 37px;
        line-height: 36px;
    }
    blockquote {
        margin: 37px 40px;
        p {
            font-style: italic;
        }
    }
    .cite {
        font-style: italic;
    }
    dl {
        margin: 0 20px;
    }
    dt {
        font-weight: bold;
    }
    dd {
        margin: 0 0 20px;
    }
    ul, ol {
        font-size: 16px;
        list-style-type: square;
        margin: 16px 0;
        padding: 0 0 0 40px;
        display: block;
    }
    ol {
        list-style-type: decimal;
    }
    li > ul, li > ol {
        margin: 0;
    }
    table {
        border-bottom: 1px solid #ededed;
        border-collapse: collapse;
        border-spacing: 0;
        font-size: 13px;
        line-height: 2;
        margin: 0 0 20px;
        width: 100%;
    }
    th {
        font-weight: bold;
        text-transform: uppercase;
    }
    td {
        border-top: 1px solid #ededed;
        padding: 6px 10px 6px 0;
    }
    address {
        font-style: italic;
        margin: 0 0 24px;
    }
    a {
        color: red;
    }
    abbr[title] {
        border-bottom: 1px dotted;
    }
    cite, em, var {
        font-style: italic;
    }
    kbd {
        color: #c7254e;
        background-color: #f9f2f4;
        white-space: nowrap;
        border-radius: 4px;
    }
    pre {
        padding: 20px;
        color: #666;
        font-family: monospace;
        font-size: 14px;
        margin: 20px 0;
    }
    img {
        margin-top: 13px;
        @media (min-width: @screen-sm-min) {
            margin-bottom: 53px;
        }
        &.aligncenter {
            margin: 5px auto;
        }
        &.alignleft {
            margin-left: -60px;
        }
        &.alignleft {
            margin: 5px 20px 5px 0;
        }
        &.size-full, &.size-large, &.wp-post-image {
            height: auto;
            max-width: 100%;
        }
        &.alignright {
            margin-right: -60px;
        }
        &.alignright {
            margin: 5px 0 5px 20px;
        }
    }
    .alignleft {
        float: left;
    }
    .alignright {
        float: right;
    }
    .entry-content .wp-caption.alignright {
        margin-right: -60px;
    }
    .entry-content img, .entry-summary img, .comment-content img, .widget img, .wp-caption {
        max-width: 100%;
    }
    figure.wp-caption.alignright {
        margin: 5px 0 5px 20px;
    }
    figure.wp-caption.alignleft {
        margin: 5px 20px 5px 0;
    }
    .wp-caption .wp-caption-text, .entry-caption, .gallery-caption {
        color: #220e10;
        font-size: 12px;
        font-style: italic;
        font-weight: 300;
        margin: 0 0 24px;
    }
    .wp-caption.alignleft {
        margin-left: -60px;
    }
    .wp-caption {
        img {
            margin: 0;
        }
    }
    hr {
        margin-top: 82px;
        display: block;
        max-width: 100px;
        border-color: black;
    }
    .quote {
        width: 100%;
        font-size: 26px;
        color: @secondary-color;
        background-color: red;
        text-align: center;
        font-style: italic;
        line-height: 41px;
        font-weight: 100;
        letter-spacing: -1px;
        padding: 10px 10px 0;
        @media (min-width: @screen-sm-min) {
            padding: 52px 14px 16px 33px;
            margin-right: -185px;
            margin-left: 10px;
            margin-top: 10px;
            float: right;
            width: 350px;
        }
        .author {
            color: #BF9441;
            text-align: right;
            letter-spacing: 0px;
            margin: 16px 23px;
        }
    } 
    .content-image {
        position: relative;
        @media (max-width: @screen-sm-max) {
            margin-bottom: 37px;
        }
        .credits {
            margin: 0;
            font-size: 11px;
            font-style: italic;
            line-height: 20px;
            color: gray;
            letter-spacing: 2.2px;
            @media (min-width: @screen-sm-min) {
                width: 160px;
                right: -177px;
                position: absolute;
                bottom: 60px;
            }
            @media (min-width: @screen-xlg-min) {
                width: 211px;
                right: -220px;
            }
            &:after {
                content: "";
                display: block;
                width: 40px;
                height: 1px;
                background-color: @primary-color;
                margin-top: 13px;
            }
        }
    }
    .content-gallery {
        position: relative;
        margin-bottom: 50px;
        .carusel-gallery-photo {
            width: 100%;
            height: 350px;
            @media (min-width: @screen-xlg-min) {
                height: 530px;
            }
        }
        .carusel-gallery-text {
            background-color: green;
            color: @secondary-color;
            width: 100%;
            height: 375px;
            margin: 4px auto;
            @media (min-width: @screen-sm-min) {
                width: 350px;
                height: 320px;
                margin: 30px auto;
            }
            @media (min-width: @screen-md-min) {
                margin: 0 78.2% 0 0;
                position: absolute;
                bottom: 0;
                right: 0;
            }
            .gallery-cell {
                padding: 24px;
            }
            .title-post-gallery {
                font-size: 18px;
                font-style: normal;
                letter-spacing: 3.7px;
                line-height: 33px;
                margin-bottom: 13px;
                &:after {
                    content: "";
                    display: block;
                    width: 30px;
                    height: 1px;
                    margin: 20px 0 0 2px;
                    background-color: @secondary-color;
                }
            }
            p {
                font-style: italic;
                line-height: 33px;
                letter-spacing: 2.5px;
            }
            .flickity-page-dots {
                bottom: 24px;
            }
        }
        .carusel-gallery-tabs {
            text-align: center;
            @media (min-width: @screen-sm-min) {
                text-align: right;
            }
            li {
                display: inline-block;
                position: relative;
                top: 5px;
                height: 53px;
                width: 18.8%;
                @media (min-width: @screen-sm-min) {
                    height: 84px;
                    width: 19.3%;
                }
                @media (min-width: @screen-md-min) {
                    width: 15%;
                }
            }
        }
    }

    // intro commenst
    .intro-comments {
        font-style: italic;
        margin-top: 121px;
        margin-bottom: 90px;
        position: relative;
        .count-comments {
            font-size: 20px;
            margin-left: 56px;
            letter-spacing: 2px;
            position: relative;
        }
        .number {
            font-size: 36px;
            position: absolute;
            left: -56px;
            top: -21px;
            &:after {
                content: "";
                width: 1px;
                height: 50px;
                background: fade(@primary-color, 30%);
                display: block;
                position: absolute;
                top: 0;
                transform: rotate(31deg);
                left: 50px;
            }
        }
        .leave-reply {
            font-size: 13px;
            display: block;
            margin: 11px 0 0 22px;
            letter-spacing: 2.5px;
            color: @primary-color;
            text-decoration: none;
            position: relative;
            &:before {
                content: "";
                height: 17px;
                width: 15px;
                display: block;
                position: absolute;
                left: -25px;
                top: 3px;
                background-image: url('../images/arrow.png');
                transform: rotate(90deg);
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
            }
        }
        .share {
            font-size: 13px;
            right: 17px;
            margin-top: 40px;
            margin-left: 3px;
            @media (min-width: @screen-sm-min) {
                position: absolute;
                top: -10px;
                margin-top: 0;
            }
            li {
                display: inline-block;
                a {
                    color: @primary-color;

                    i {
                        position: relative;
                        top: 2px;
                    }
                }
                &:first-child {
                    margin-right: 15px;
                    letter-spacing: 2px;
                }
                &:not(:first-child) {
                    border: 1px solid fade(@primary-color, 10%);
                    width: 35px;
                    height: 35px;
                    text-align: center;
                    font-size: 20px;
                    padding-top: 2px;
                    border-radius: 50%;
                    margin-right: 1px;
                }
            }
        }
    }

    // comments
    .media {
        margin-top: 26px;
        .blog-post-author {
            width: 110px;
            height: 110px;
        }

        .media {
            margin-top: 50px;
            border-left: 10px solid fade(@primary-color, 3%);
            position: relative;
            left: -14px;
            padding-left: 30px;
            margin-bottom: 30px;
        }
        hr {
            margin: 12px auto;
        }
    }
    .media-heading {
        font-size: 13px;
        // font-style: italic;
        margin: 23px 0 28px 0;
        line-height: 20px;
    }
    .media > .pull-left {
        display: none;
        @media (min-width: @screen-sm-min) {
            display: block;
        }
    }
    .media-body {
        padding-left: 14px;
        p {
            line-height: 29px;
        }
    }
    .title-form {
        margin-top: 50px;
        font-size: 20px;
        font-style: italic;
        margin-bottom: 30px;
    }
    form {
        padding: 10px 0 0 0;
        .form-group {
            padding: 0;
            &.inline {
                @media (min-width: @screen-sm-min) {
                    padding-left: 30px;
                    &:first-child {
                        padding-left: 0;
                        padding-right: 30px;
                    }
                }
            }
            textarea {
                height: 150px;
            }
        }
        .btn {
            margin-right: 0;
        }
    }
}

// .tiled-gallery{clear:both;margin:0 0 20px;overflow:hidden;}.tiled-gallery img{margin:2px !important;}.tiled-gallery .gallery-group{float:left;position:relative;}.tiled-gallery .tiled-gallery-item{float:left;margin:0;position:relative;width:inherit;}.tiled-gallery .gallery-row{overflow:hidden;}.tiled-gallery .tiled-gallery-item a{background:transparent;border:none;color:inherit;margin:0;padding:0;text-decoration:none;width:auto;}.tiled-gallery .tiled-gallery-item img,
// .tiled-gallery .tiled-gallery-item img:hover{background:none;border:none;box-shadow:none;max-width:100%;padding:0;vertical-align:middle;}.tiled-gallery-caption{background:#eee;background:rgba( 255,255,255,0.8 );color:#333;font-size:13px;font-weight:400;overflow:hidden;padding:10px 0;position:absolute;bottom:0;text-indent:10px;text-overflow:ellipsis;width:100%;white-space:nowrap;}.tiled-gallery .tiled-gallery-item-small .tiled-gallery-caption{font-size:11px;}.widget-gallery .tiled-gallery-unresized{visibility:hidden;height:0px;overflow:hidden;}.tiled-gallery .tiled-gallery-item img.grayscale{position:absolute;left:0;top:0;}.tiled-gallery .tiled-gallery-item img.grayscale:hover{opacity:0;}.tiled-gallery.type-circle .tiled-gallery-item img{border-radius:50% !important;}.tiled-gallery.type-circle .tiled-gallery-caption{display:none;opacity:0;}
// .tiled-gallery-caption {
//     bottom: -42px;
//     bottom: 0px;  
//     -webkit-transition: -webkit-transform .35s;
//     transition: transform .35s;
//     -webkit-transform: translate3d(0,100%,0);
//     transform: translate3d(0,100%,0);
// }
// .tiled-gallery-item:hover {
//     .tiled-gallery-caption {
//         // bottom: 0px;  
//         -webkit-transform: translate3d(0,0,0);
//         transform: translate3d(0,0,0);     
//     }
// }

.post-sidebar-left {
    @media (max-width: @screen-md-max) {
        display: none;
    }
    padding: 65px 0 0 0;
    @media (min-width: @screen-xlg-min) {
        padding: 65px 0 0 95px;
    }
    .post-sidebar-title {
        font-size: 18px;
        font-style: italic;
        margin: 3px 0 50px;
        letter-spacing: 3.5px;
        &:after {
            content: "";
            display: block;
            width: 40px;
            height: 2px;
            background-color: @primary-color;
            margin: 14px 0 0;
        }
    }
    .post-sidebar-title-post {
        margin: 17px 3px;
        letter-spacing: 3.5px;
        line-height: 26px;
    }
    .blog-post-header {
        margin: 23px 3px 40px;
    }
    .blog-post-author {
        width: 40px;
        height: 40px;
    }
    .blog-post-date {
        float: left;
        text-align: left;
        top: 0px;
        margin: 7px 0 0 10px;
        font-style: normal;
        letter-spacing: 2px;
        line-height: 15px;
    }
}
.section.post-whidout-sidebar {
    overflow: visible;
}
.post-whidout-sidebar:before {
    content: "";
    background-color: @secondary-color;
    display: block;
    width: 50%;
    height: 102px;
    position: absolute;
    right: 0;
    margin-top: -100px;
}

.container-post-sidebar-right {
    div[class*="container-"] {
        @media (min-width: @screen-sm-min) {
            padding: 0 25px;
        }
        @media (min-width: @screen-md-min) {
            padding: 0 125px;
        }
    }
    .sidebar-content {
        padding: 0; 
        @media (min-width: @screen-xlg-min) {
            padding: 0 0px 0 37px;
        }
    }
    .post-sidebar-right {
        @media (min-width: @screen-md-min) {
            padding-right: 50px;
        }
    }
    .feature-image {
        position: relative;
        img {
            width: 100%;
            margin: 0;
            display: block;
        }
        .share-post {
            position: absolute;
            bottom: 20px;
            left: 20px;
            li {
                display: block;
                margin-top: 15px;
            }
        }
    }
    .post-info {
        text-align: center;
        font-size: 14px;
        font-style: italic;
        margin-top: 40px;
        margin-bottom: 22px;
        letter-spacing: 2.5px;
        li {
            display: inline-block;
            @media (min-width: @screen-sm-min) {
                margin-left: 35px;
            }
            &.post-author {
                position: relative;
                padding-left: 55px;
                .blog-post-author {
                    width: 40px;
                    height: 40px;
                    position: absolute;
                    top: -10px;
                    left: 0;
                }
            }
            &.post-tags,
            &.post-date {
                &:before {
                    content: "";
                    display: inline-block;
                    width: 21px;
                    height: 21px;
                    background-repeat: no-repeat;
                    background-position: center center;
                    position: relative;
                    top: 6px;
                    margin-right: 11px;
                } 
            }
            &.post-date {
                &:before {
                    background-image: url('../images/clock.png');
                }
            }
            &.post-tags {
                &:before {
                    background-image: url('../images/tags.png');
                }
            }
        }
    }
    .primary-title {
        margin-bottom: 57px;
        border-bottom: 4px double @primary-color;
        padding-bottom: 50px;
    }
    .columns-post {
        @media (min-width: @screen-sm-min) {
            margin-top: 60px;
            -moz-column-count: 2;
            -moz-column-gap: 62px;
            -webkit-column-count: 2;
            -webkit-column-gap: 62px;
            column-count: 2;
            column-gap: 62px;
        }
        @media (min-width: @screen-md-min) {
            padding-right: 53px;
        }
    }
    .quote {
        font-size: 37px;
        font-style: italic;
        font-family: Georgia, Times, 'Times New Roman', serif;
        float: none;
        width: 100%;
        background-color: transparent;
        color: #000;
        margin: 0 0 30px 0;
        text-align: left;
        border-bottom: 1px solid;
        padding: 40px 20px;
        @media (min-width: @screen-lg-min) {
            padding: 0px 20px 40px;
        }
        @media (min-width: @screen-xlg-min) {
            padding: 0 70px 46px;
        }
    }
}

.menu-bar {
    .sidebar-content {
        margin-top: 25px;
    }
    .post-sidebar-right {
        @media (min-width: @screen-sm-min) {
            margin-top: 40px;
        }
    }
}

// .comments-form {
//     margin-bottom: 150px;
//     @media (min-width: @screen-md-min) {
//         margin-bottom: 230px;
//     }
// }

.ico-comments {
    background-image: url('../images/comments.png');
}
.ico-share {
    background-image: url('../images/share.png')
}