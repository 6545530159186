/* Pace.js */
.pace {
  -webkit-pointer-events: none;
  pointer-events: none;

  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;


    // position: fixed;
    // width: 100%;
    // height: 100%;
    // left: 0;
    // top: 0;
    // background-color: @loader-color-background;
    // opacity: 1;
    // transition: top 0.50s cubic-bezier(0.77, 0, 0.175, 1);
    // z-index: 9;

    // .pace-done & {
    //     top: -100%;
    // }
}

.pace-inactive {
    display: none;
    .pace-progress {  display: none;}
}

.pace .pace-progress {
  background: @loader-color;
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 100%;
  width: 100%;
  height: 5px;
}
// // 

#layer-loade {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: @loader-color-background;
    opacity: 1;
    transition: top 0.50s cubic-bezier(0.77, 0, 0.175, 1);
    z-index: 9;

    .pace-done & {
        top: -100%;
    }
}

.logo-intro {
  .ir( 184px; 66px; '@{img-demo-path}/logo/logo-black.png'; center center);
  margin: 0 auto;
  .vcenter();
}