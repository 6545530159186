.social-links {
    margin: 30px 0;
    text-align: center;
    @media (min-width: @screen-xlg-min) {
        margin: 50px 0;
    }
    li {
        display: inline-block;
        text-align: center;
        margin: 0 10px;
        font-size: 19px;
        a {
            color: @color-paragraph;
            transition: color 0.25s cubic-bezier(0.77, 0, 0.175, 1);
            &:hover {
                color: @primary-color;
            }
        }
    }
    &.ontop {
        position: absolute;
        right: 25px;
        top: 3px;
        z-index: 1;
        display: none;
        @media (min-width: @screen-sm-min) {
            display: block;
        }
        @media (max-width: @screen-md-min) {
            li {
                margin: 0 5px;
            }
        }
        @media (min-width: @screen-xlg-min) {
            top: 21px;
        }
    }
}