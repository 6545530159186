/* Countdown */
.countdown-row {
    display: block;
    text-align: center;
}

.countdown-section {
    border-left: 1px solid #dadada;
    border-color: rgba(218,218,218,0.8);
    color: #dadada;
    display: inline-block;
    font-size: 12px;
    letter-spacing: -1px;
    padding: 22px 0px 8px;
    width: 23%;
    text-align: center;
    text-transform: uppercase;
    -webkit-box-sizing: border-box;
    -moz-box-sizing:    border-box;
    box-sizing:         border-box;
    float: left;
    @media (min-width: @screen-sm-min) {
        width: 25%;
        letter-spacing: 1px;
        padding: 42px 12px 28px;
    }
}

.countdown-section:first-child {    
    border-left: 0;
}

.countdown-amount {
    color: #fff;
    display: block;
    // font-family: 'Roboto Slab', serif;
    font-size: 30px;
    font-weight: 700;
    letter-spacing: normal;
    line-height: 1;
    padding-bottom: 21px;
    @media (min-width: @screen-sm-min) {
        font-size: 60px;
    }
}

body.comingsoon {
    form {
        margin-top: 80px;
    }
    .primary-title {
        margin-bottom: 80px;
    }
}