.page-albums {
    padding: 0;
}
.container-page {
    .vcenter();
}
.albums-container {
    margin: 0px auto;
    width: 70%;
    // max-width: 600px;
    @media (min-width: @screen-lg-min) {
        width: 80%;
        max-width: 900px;
    }
    @media (min-width: @screen-xlg-min) {
        max-width: 1700px;
    }
}
#albums-carousel {
    .flickity-prev-next-button {
        background-repeat: no-repeat;
        background-position: center;
        opacity: 0.1;
        background-size: 32px;
        &,
        &:hover {
            background-color: transparent;
        }
        @media (min-width: @screen-xlg-min) {
            background-size: auto;
        }
        svg {
            display: none;
        } 
        &.previous {
            background-image: url('@{img-path}/left.svg');
            left: -55px;
            @media (min-width: @screen-xlg-min) {
                left: -86px;
            }
        }
        &.next {
            background-image: url('@{img-path}/right.svg');
            right: -55px;
            @media (min-width: @screen-xlg-min) {
                right: -86px;
            }
        }
        &:hover {
            opacity: 0.5;
        }
    }
    .flickity-page-dots {
        @media (min-width: @screen-xlg-min) {
            bottom: -60px;
        }
        .dot {
            background-color: #999;
            @media (min-width: @screen-xlg-min) {
                width: 13px;
                height: 13px;
            }
        }
    }
    .carousel-cell {
        width: 100%;
        @media (min-width: @screen-md-min) {
            width: 49.5%;
        }
        height: auto;
        min-height: 200px;
        margin-right: 2px;
        margin: 0 3px;
        .album {
            background-color: #F2F2F2;
            position: relative;
            overflow: hidden;
            padding: 10px;
            @media (min-width: @screen-lg-min) {
                padding: 25px;
            }
            &:first-child {
                margin-bottom: 4px;
            }
            .content-text,
            .content-photo {
                width: 50%;
                position: relative;
                height: 150px;
                @media (min-width: @screen-lg-min) {
                    height: 180px;
                }
                @media (min-width: @screen-xlg-min) {
                    height: 307px;
                }
            }
            .content-text {
                float: left;
                @media (min-width: @screen-xlg-min) {
                    width: ~'calc(100% - 381px)';
                }
                &,
                p,
                h3 {
                    text-align: right;
                }
                .text {
                    .vcenter();
                    padding: 0 20px 0 0;
                    @media (min-width: @screen-xlg-min) {
                        // padding: 0 55px 0 0;
                        padding: 0 35px 0 0;
                    }
                    h3 {
                        font-size: 19px;
                        line-height: 19px;
                        margin-bottom: 10px;
                        @media (min-width: @screen-sm-min) {
                            font-size: 26px;
                            margin-bottom: 5px;
                        }
                        @media (min-width: @screen-lg-min) {
                            margin-bottom: 30px;
                        }
                    }
                    p {
                        font-size: 14px;
                        line-height: 15px;
                        @media (min-width: @screen-sm-min) {
                            font-size: 15px;
                            line-height: 17px;
                        }
                        @media (min-width: @screen-lg-min) {
                            line-height: 23px;
                        }
                    }
                }
            }
            .content-photo {
                float: right;
                cursor: pointer;
                // background-color: @primary-color;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center center;
                position: relative;
                @media (min-width: @screen-xlg-min) {
                    background-size: auto;
                    width: 381px;
                }
            }
        }
        &:nth-child(even) {
            .album {
                .content-text {
                    float: right;
                    &,
                    p,
                    h3 {
                        text-align: left;
                    }
                    .text {
                        padding: 0 0 0 20px;
                        @media (min-width: @screen-xlg-min) {
                            padding: 0 0 0 55px;
                        }
                    }
                }
                .content-photo {
                    float: left;
                }
            }
        }
    }
}

.lightgallery {
    &,
    li,
    li img {
        height: 100%;
    }
    li {
        display: none;
        &:first-child {
            display: block;
            position: relative;
            img {
                opacity: 0;
            }
            // efecto rollover
            &:after,
            &:before {
                content: "";
                position: absolute;
                display: block;
                transition: all 0.25s cubic-bezier(0.77, 0, 0.175, 1);
            }
            &:after {
                border: 1px solid;
                border-color: transparent;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
            }
            &:before {
                width: 100%;
                height: 100%;
                background-image: url('@{img-path}/plus-rollover.svg');
                background-size: 50px;
                background-position: center;
                background-repeat: no-repeat;
                background-color: fade(@primary-color, 25%);
                top: 0;
                left: 0;
                opacity: 0;
            }
            &:hover {
                &:after {
                    border-color: @secondary-color;
                    width: 80%;
                    height: 80%;
                    top: 10%;
                    left: 10%;
                }
                &:before {
                    opacity: 1;
                }
            }
        }
        img {
            width: 100%;
            // .vcenter();
        }
    }
}