form.newsletter-form {
    max-width: 660px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    input {
        color: @secondary-color;
        display: inline-block;
        font-weight: @weight-bold;
        font-size: 14px;
        letter-spacing: 3.2px;
        border-color: @secondary-color;

        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
          color: @secondary-color;
        }
        &::-moz-placeholder { /* Firefox 19+ */
          color: @secondary-color;
        }
        &:-ms-input-placeholder { /* IE 10+ */
          color: @secondary-color;
        }
        &:-moz-placeholder { /* Firefox 18- */
          color: @secondary-color;
        }
    }
    input[type=submit] {
        color: @secondary-color;    
    }
    .form-group {
        margin-bottom: 30px;
        width: 480px;
        float: left;
        padding: 0 20px 0 0;
        @media (max-width: @screen-xs-max) {
            width: 100%;
            padding: 0 10px;
        }
    }
    .btn {
        width: ~"calc(100% - 20px)";
        float: none;
        margin: 0 10px;
        @media (min-width: @screen-sm-min) {
            margin: 0px;
            float: left;
            width: auto;
        }
    }
}