#blog-intro-slider01 { background-image: url('../images/demo/blog/slider01.jpg'); }
#blog-photo-post1 { background-image: url('../images/demo/blog/photo-post1.jpg'); }
#blog-photo-post2 { background-image: url('../images/demo/blog/photo-post2.jpg'); }
#blog-photo-post3 { background-image: url('../images/demo/blog/photo-post3.jpg'); }

#post2-intro-slider01 { background-image: url('../images/demo/blog/slider02.jpg'); }
#blog2-post-author1 { background-image: url('../images/demo/blog/author.jpg'); }

#post1-pagination-oldpost { background-image: url('../images/demo/blog/pagination-oldpost.jpg'); }
#post1-pagination-newpost { background-image: url('../images/demo/blog/pagination-newpost.jpg'); }

#post1-comments1 { background-image: url('../images/demo/blog/comments1.jpg'); }
#post1-comments2 { background-image: url('../images/demo/blog/comments2.jpg'); }
#post1-comments3 { background-image: url('../images/demo/blog/comments3.jpg'); }
#post1-comments4 { background-image: url('../images/demo/blog/comments4.jpg'); }
