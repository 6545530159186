body.dark {

    // intro
    #layer-loade {
        background-color: @primary-color;
    }
    .pace .pace-progress {
        background-color: @secondary-color;       
    }
    .logo-intro {
        background-image: url("@{img-demo-path}/logo/logo-white.png");
    }
    
    // layout
    .logo a {
        background-image: url('@{img-demo-path}/logo/logo-white.png');
    }
    &.left-menu {
        .navbar {
            &,
            .navbar-header {
                background-color: @primary-color;
            }
            .navbar-toggle .icon-bar {
                background-color: @secondary-color;
            }
            .navbar-brand {
                background-image: url('@{img-demo-path}/logo/logo-white.png');
            }
        }
        #navbar .close-nav {
            background-image: url('@{img-path}/cross-white.svg');
        }
    }

    .nav > li > a {
        color: @secondary-color;
    }
    .nav > li.active > a {
        @media (max-width: @screen-sm-min) {
            background-color: #424242;
        }
    }

    // pages
    .layer-page {
        background-color: @primary-color;
    }
    .close-layer {
        background-image: url('@{img-path}/cross-white.svg');
    }
    
    h3 {
        color: @secondary-color;
    }
    .page-about .author:before {
        background-color: @secondary-color;
    }
    .page-about .image:after {
        border-color: @secondary-color;
    }

    #albums-carousel .carousel-cell .album {
        background-color: #333333;
        .content-text .text p {
            color: @secondary-color;
        }
    }
    #albums-carousel .flickity-prev-next-button {
        opacity: 0.7;
        &:hover {
            opacity: 1;
        }
        &.previous {
            background-image: url('@{img-path}/left-white.svg');
        }
        &.next {
            background-image: url('@{img-path}/right-white.svg');   
        }
    }

    .address {
        color: #d0d0d0;
    }
    input, textarea {
        border-color: fade(@secondary-color, 60%);
        color: @secondary-color;
    }
    input[type=submit] {
        &:hover {
            background-color: @secondary-color;
            color: @primary-color;
        }
    }
}