.post-share {
    li {
        display: inline-block;
        font-size: 13px;
        font-family: @secondary-font;
        font-weight: 100;
        margin-right: 15px;
        @media (min-width: @screen-md-min) {
            margin-right: 19px;
        }
        i {
            color: #EBEBEB;
            font-size: 20px;
            margin-right: 6px;
            position: relative;
            top: 2px;
        }
    }
}